import React, { lazy, Suspense } from "react";
import { Route, HashRouter as Router, Switch } from "react-router-dom";
import { StateMachineProvider, createStore } from "little-state-machine";
// import { LinkedInPopUp } from "react-linkedin-login-oauth2";

import ScrollToTop from "../../utils/ScrollToTop";

// import Nav from "../../components/Nav";
// import Footer from "../../components/Footer";
// import Landing from "../../components/Landing";
// import BecomeAnExpert from "../../components/StaticPages/becomeAnExpert";
// import BecomeAPartner from "../../components/StaticPages/becomeAPartner";
// import ApplyAsPartner from "../../components/ApplyAsPartner";
// import SpeakWithAdvisor from "../../components/SpeakWithAdvisor";
// import CommonThankYouPage from "../../components/StaticPages/commonThankYouPage";
// import NotFoundPage from "../../components/ErrorPages/notFoundPage";
// import CreateAProfile from "../../components/CreateAProfile";
// import ContactUs from "../../components/ContactUs";
// import Experts from "../Experts";
// import Expert from "../Expert";
// import Skill from "../Skill";
// import Skills from "../Skills";
// import Company from "../Company";
// import Type from "../Type";
// import RequestThisTeam from "../RequestThisTeam";

// import Step1 from "../../components/RequestForTeam/Step1";
// import Step2 from "../../components/RequestForTeam/Step2";
// import Step3 from "../../components/RequestForTeam/Step3";
// import Step4 from "../../components/RequestForTeam/Step4";
// import Step5 from "../../components/RequestForTeam/Step5";
// import Step6 from "../../components/RequestForTeam/Step6";
// import Result from "../../components/RequestForTeam/Result";
// import ExpertForm from "../../components/RequestForExpert/Form";

const Nav = lazy(() => import("../../components/Nav"));
const Footer = lazy(() => import("../../components/Footer"));
const Landing = lazy(() => import("../../components/Landing"));
const NotFoundPage = lazy(() =>
  import("../../components/ErrorPages/notFoundPage")
);
const ApplyAsPartner = lazy(() => import("../../components/ApplyAsPartner"));
const SpeakWithAdvisor = lazy(() =>
  import("../../components/SpeakWithAdvisor")
);
const CommonThankYouPage = lazy(() =>
  import("../../components/StaticPages/commonThankYouPage")
);
const CreateAProfile = lazy(() => import("../../components/CreateAProfile"));
const ContactUs = lazy(() => import("../../components/ContactUs"));
const Experts = lazy(() => import("../Experts"));
const Expert = lazy(() => import("../Expert"));
const Skill = lazy(() => import("../Skill"));
const Company = lazy(() => import("../Company"));
const Type = lazy(() => import("../Type"));
const RequestThisTeam = lazy(() => import("../RequestThisTeam"));
const Step1 = lazy(() => import("../../components/RequestForTeam/Step1"));
const Step2 = lazy(() => import("../../components/RequestForTeam/Step2"));
const Step3 = lazy(() => import("../../components/RequestForTeam/Step3"));
const Step4 = lazy(() => import("../../components/RequestForTeam/Step4"));
const Step5 = lazy(() => import("../../components/RequestForTeam/Step5"));
const Step6 = lazy(() => import("../../components/RequestForTeam/Step6"));
const Result = lazy(() => import("../../components/RequestForTeam/Result"));
const ExpertForm = lazy(() => import("../../components/RequestForExpert/Form"));
const PrivacyPolicy = lazy(() => import("../../components/PrivacyPolicy"));
// const TermsAndConditions = lazy(() =>
//   import("../../components/TermsAndConditions")
// );

createStore({ data: {} });

function App() {
  return (
    <Router basename={process.env.REACT_APP_BASE_PATH}>
      <ScrollToTop />
      <div className="content-wrapper">
        <Nav />
        <StateMachineProvider>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route path="/" component={Landing} exact />
              {/* <Route path="/" component={LinkedInPopUp} exact /> */}
              {/* <Route path="/index.html" component={Landing} exact /> */}
              <Route path="/experts" component={Experts} exact />
              <Route path="/experts/:slug" component={Expert} exact />
              {/* <Route path="/browse-services" component={Skills} exact /> */}
              <Route path="/companies/:slug" component={Company} exact />
              <Route path="/skills/:slug" component={Skill} exact />
              <Route path="/types/:slug" component={Type} exact />
              <Route path="/privacy-policy" component={PrivacyPolicy} exact />
              {/* <Route
                path="/terms-and-conditions"
                component={TermsAndConditions}
                exact
              /> */}
              {/* <Route
                path="/become-an-expert"
                component={BecomeAnExpert}
                exact
              /> */}
              {/* <Route
                path="/become-a-partner"
                component={BecomeAPartner}
                exact
              /> */}
              {/* <Route
                path="/become-a-partner/thanks"
                component={CommonThankYouPage}
                exact
              /> */}
              <Route
                path="/apply-as-partner"
                component={ApplyAsPartner}
                exact
              />
              <Route
                path="/speak-with-advisor"
                component={SpeakWithAdvisor}
                exact
              />
              <Route
                path="/speak-with-advisor/thanks"
                component={CommonThankYouPage}
                exact
              />
              <Route path="/contact-us" component={ContactUs} exact />
              <Route
                path="/contact-us/thanks"
                component={CommonThankYouPage}
                exact
              />
              <Route
                path="/create-a-profile"
                component={CreateAProfile}
                exact
              />
              <Route
                path="/create-a-profile/thanks"
                component={CommonThankYouPage}
                exact
              />
              <Route
                path="/request-this-team"
                component={RequestThisTeam}
                exact
              />
              <Route
                path="/request-this-team/thanks"
                component={CommonThankYouPage}
                exact
              />

              <Route path="/request-for-team" component={Step1} />
              <Route path="/request-for-team-step2" component={Step2} />
              <Route path="/request-for-team-step3" component={Step3} />
              <Route path="/request-for-team-step4" component={Step4} />
              <Route path="/request-for-team-step5" component={Step5} />
              <Route path="/request-for-team-step6" component={Step6} />
              <Route path="/request-for-team-result" component={Result} />

              <Route
                path="/request-for-expert/:slug/:name/:type"
                component={ExpertForm}
              />
              <Route
                path="/request-for-expert/thanks"
                component={CommonThankYouPage}
                exact
              />

              <Route component={NotFoundPage} />
            </Switch>
          </Suspense>
        </StateMachineProvider>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
