import React from "react";
import { HashRouter as Router } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { ApolloProvider } from "react-apollo";

import App from "./containers/App";
import client from "./utils/apolloClient";
import ScrollToTop from "./utils/ScrollToTop";

import "./styles/fonts.css";
import "./styles/index.css";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Router>
    <ScrollToTop />
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </Router>
);
